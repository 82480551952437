@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/_variables.scss';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/_spacing';

@mixin visuallyHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.visuallyHidden {
  @include visuallyHidden();
}

.hidden {
  display: none;
}

.separator {
  border-top: 1px solid $colour-grey-15;
  clear: both;
  padding-top: spacing(2);
}

.strikethrough {
  text-decoration: line-through;
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
