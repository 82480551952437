@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/_variables.scss';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/_spacing';

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/

:global(.focus-visible) {
  outline: $colour-grey-15 solid 2px;
}

:global(.js-focus-visible) :focus:not(:global(.focus-visible)) {
  outline: none;
  -moz-appearance: none;
  box-shadow: none;
}
/* Custom Rule from browse-ui */
html,
body {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
}

body {
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}

h4 {
  margin-bottom: spacing(1);
}

img {
  max-width: 100%;
}