@import 'configuration';
@import 'utilities';

.pageContainer {
  margin: 0 auto;
  max-width: $site-max-width;
  padding-left: spacing(2);
  padding-right: spacing(2);
}

.fullBleed {
  margin-left: calc((-100vw + 100%) / 2);
  width: 100vw;
}

.pageRow {
  padding-top: $spacing-section;
}

.flexContainer {
  @include grid();
}

.col6 {
  @include grid-col(6);
}

.centralCol {
  @include grid-col();
  @include breakpoint(small) {
    @include grid-col(8);
    @include grid-col-offset-left(2);
  }
}

.main {
  margin: 0 auto;
  max-width: $site-max-width;
  position: relative;

  @include breakpoint(961) {
    position: static;
  }

  &:after {
    transition: opacity 0.3s ease-in-out;
    content: '';
    @include overlay;
    opacity: 0;
    pointer-events: none;
  }
  &:global(.fadeOut) {
    &:after {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }
}
